import {inject, bindable} from 'aurelia-framework';
import AppState from '../../app-state';
import {EventAggregator} from 'aurelia-event-aggregator';
import {TestActions} from '../../actions/test-actions';
import {WebSocketService} from '../../../services/websocket-service.js';
import Config from '../../../config';
import {DateTool} from '../../tools/date-tool.js';



@inject(AppState, EventAggregator, TestActions, WebSocketService, Config, DateTool)
export class RunningTest{
    constructor(state, ea, ta, ws, config,dt){
        this.state = state;
        this.ea = ea;
        this.config = config;
        this.textcount = 0;
        this.text = "";
        this.actions = ta;
        this.websocket = ws;
        this.datetool = dt;
        this.measurements = ["coreTemperature", "heatStrainIndex", "totalSweating", "heartrate"];
        this.replaying = false;
        this.repLimit = 25;
        this.repSkip = 0;
        this.person = -1;
        this.single = false;
        this.time = "00:00:00";
    }
    bind(){
        if(this.state.selectedTest.status == 'active'){
            this.websocket.connect(this.state.selectedTest._key);
            this.ea.publish("test-status-change", {status:"active"});
        }
    }
    attached(){
        this.textinterval = setInterval(()=>{
            this.textcount++;
            this.text = ".".repeat(this.textcount);
            if(this.textcount >= 3){
                this.textcount = -1;
            }
        }, 500);
        this.actions.getTestPersons().then(()=>{
            if(this.state.selectedTest.persons.length == 1){
                this.person = 0;
                this.single = true;
            }
        });
        this.settimesub = this.ea.subscribe("set-test-time", (t)=>{
            this.time = this.datetool.timeFromSeconds(t);
        });
        /*if(this.state.selectedTest.status == 'active'){
            this.websocket.connect(this.state.selectedTest._key);
        }*/

    }
    selectPerson(index){
        console.log(index+" "+this.person);
        if(index == this.person){
            this.person = -1;
        }else{
            this.person = index;
        }
    }
    addNote(){
        this.showNotes = false;
    }
    replay(){
        this.state.replay.on = true;
        this.state.replay.speed = parseInt(this.treplaycoefficient.value);
        this.showReplayModal = false;
        this.ea.publish("personcard-replay", {run: true})
    }
    stopReplay(){
        this.state.replay.on = false;
        this.ea.publish("personcard-replay", {run: false})
    }
    getNextMeasurements(){
        if(this.state.replay.on){
            return;
        }
        this.repSkip+=this.repLimit;
        this.ea.publish("personcard-get-measurements", {limit: this.repLimit, skip: this.repSkip, reverse: false});
    }
    getPreviousMeasurements(){
        if(this.state.replay.on){
            return;
        }
        if(this.repSkip > 0 && this.repSkip-this.repLimit >= 0){
            this.repSkip-=this.repLimit;
        }else{
            this.repSkip = 0;
        }
        this.ea.publish("personcard-get-measurements", {limit: this.repLimit, skip: this.repSkip, reverse: true});
    }
    detached(){
        if(this.textinterval){
            clearInterval(this.textinterval);
        }
        this.settimesub.dispose();
        if(this.state.replay.on){
            console.log("lopeta replay");
            this.stopReplay();
        }
    }
    closeCase(){
        this.ea.publish("test-case-nav", {route:"test-case-list"})
    }
    changePersonCardTab(tab){
        this.ea.publish("change-personcard-tab", {tab: tab});
    }
    changeTestStatus(){
        if(this.state.selectedTest.status == 'in-active'){
            this.actions.activateTest().then(()=>{
                this.websocket.connect(this.state.selectedTest._key);
                this.ea.publish("test-status-change", {status:"active"});
            });
        }else{
            this.actions.deactivateTest();
            this.websocket.disconnect();
            this.ea.publish("test-status-change", {status:"in-active"});
        }
    }

    changeMeasure(e){
        this.ea.publish("change-personcard-measure", {measure: e.target.value});
    }
    setVariableParameters(){
        let obj = {
            _key: this.state.selectedTest._key,
            temperature: parseFloat(this.ttemperature.value),
            humidity: parseFloat(this.thumidity.value),
            convectiveHeatCoefficient: parseFloat(this.tchc.value),
            clothing : this.tcloth.value
        }
        console.log(obj);
        this.actions.setVariableParameters(obj).then(()=>{
            this.showSettings = false;
        });
    }
    getDynamicParameters(person){
        return this.actions.getDynamicParameters(this.state.selectedTest._key, person).then(()=>{
            return;
        });
    }
}